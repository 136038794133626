import { DashError } from '../core/DashError'
import { DashErrorData } from '../core/DashErrorData'
import { DashErrorLevel } from '../core/DashErrorLevel'
import { DashErrorType } from '../core/DashErrorType'

export class UnknownError extends DashError {
  constructor(data: DashErrorData) {
    super(DashErrorType.Unknown, data)
  }

  static create(e: unknown) {
    return new UnknownError({
      level: DashErrorLevel.Error,
      message: getMessage(e),
    })
  }
}

function getMessage(e: unknown) {
  if (e) {
    return String(e)
  }

  return 'An unknown error occurred'
}
