import { makeStyles, TableCell, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { JsonView } from '../../JsonView/JsonView'

interface IProps {
  readonly value: Exclude<unknown, null>
}

export function AnyPropertyRowValueForJson({ value }: IProps) {
  const styles = useStyles()

  const jsonString = useMemo(() => {
    try {
      return JSON.stringify(value, null, 2)
    } catch (error) {
      return String(value)
    }
  }, [value])

  return (
    <TableCell className={styles.root} title={jsonString}>
      <div
        className={styles.scrollbar}
        onMouseEnter={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <JsonView data={value} hideRoot={true} />
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  scrollbar: {
    padding: '12px',
    backgroundColor: tokens.colorNeutralBackground2,
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    borderRadius: '4px',
  },
})
