import { IProductEndpointData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'

interface IProps {
  readonly value: string
  readonly endpoint: IProductEndpointData
}

export function AnyPropertyRowValueForStringIndicatorEndpoint({ value, endpoint }: IProps) {
  return (
    <Text wrap block truncate title={endpoint.menu}>
      {endpoint.menu}
      {endpoint.menu && ' - '}
      {endpoint.title}
    </Text>
  )
}
