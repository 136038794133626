import { makeStyles, Table, TableBody } from '@fluentui/react-components'
import { AnyPropertyItem } from './AnyPropertyType'
import { AnyPropertyRow } from './row/AnyPropertyRow'

interface IProps {
  readonly items: AnyPropertyItem[]
}

export function AnyPropertyView({ items }: IProps) {
  const styles = useStyles()

  return (
    <Table className={styles.root}>
      <TableBody>
        {items.map((item, index) => {
          return <AnyPropertyRow key={index} item={item} />
        })}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
})
