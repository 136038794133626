import { makeStyles, TableCell, Text } from '@fluentui/react-components'
import { Row } from '../../Layout'
import { AnyPropertyRowValueForStringIndicator } from './AnyPropertyRowValueForStringIndicator'

interface IProps {
  readonly value: string
}

export function AnyPropertyRowValueForString({ value }: IProps) {
  const styles = useStyles()

  return (
    <TableCell className={styles.root} title={value}>
      <Row>
        <Text className={styles.text} wrap block truncate>
          {value}
        </Text>
        <AnyPropertyRowValueForStringIndicator value={value} />
      </Row>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  text: {
    wordBreak: 'break-word',
  },
})
