import { makeStyles, TableCell, Text, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row } from '../../Layout'

interface IProps {
  readonly value: string | number | boolean | Array<string | number | boolean>
}

export function AnyPropertyRowValueForEnum({ value }: IProps) {
  const styles = useStyles()

  const items: Array<string> = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((item) => item.toString())
    }

    return [value.toString()]
  }, [value])

  return (
    <TableCell className={styles.root}>
      <Row className={styles.container} wrap>
        {items.map((item, index) => (
          <Text key={index} title={item} size={200} className={styles.item} block wrap={false} truncate>
            {item}
          </Text>
        ))}
      </Row>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {},

  container: {
    padding: '8px 0',
    paddingRight: '8px',
    gap: '6px',
  },

  item: {
    maxWidth: '100%',
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4px 8px',
    border: `1px solid ${tokens.colorNeutralStroke2}`,
  },
})
