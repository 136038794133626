import { ReactElement, useMemo } from 'react'
import { AnyPropertyItem } from '../AnyPropertyType'
import { AnyPropertyRowValueForBoolean } from './AnyPropertyRowValueForBoolean'
import { AnyPropertyRowValueForCustom } from './AnyPropertyRowValueForCustom'
import { AnyPropertyRowValueForDatetime } from './AnyPropertyRowValueForDatetime'
import { AnyPropertyRowValueForEmail } from './AnyPropertyRowValueForEmail'
import { AnyPropertyRowValueForEmpty } from './AnyPropertyRowValueForEmpty'
import { AnyPropertyRowValueForEnum } from './AnyPropertyRowValueForEnum'
import { AnyPropertyRowValueForJson } from './AnyPropertyRowValueForJson'
import { AnyPropertyRowValueForNumber } from './AnyPropertyRowValueForNumber'
import { AnyPropertyRowValueForString } from './AnyPropertyRowValueForString'
import { AnyPropertyRowValueForLink } from './AnyPropertyRowValueForLink'

interface IProps {
  readonly item: AnyPropertyItem
}

export function AnyPropertyRowValue({ item }: IProps) {
  return useMemo((): ReactElement => {
    if (item.value === undefined || item.value === null || item.value === '') {
      return <AnyPropertyRowValueForEmpty value={item.value} />
    }

    if (typeof item.value === 'object') {
      if (Array.isArray(item.value)) {
        if (item.value.length === 0) {
          return <AnyPropertyRowValueForEmpty value={[]} />
        }
      } else {
        if (Object.keys(item.value).length === 0) {
          return <AnyPropertyRowValueForEmpty value={{}} />
        }
      }
    }

    switch (item.type) {
      case 'string':
        return <AnyPropertyRowValueForString value={item.value} />
      case 'datetime':
        return <AnyPropertyRowValueForDatetime value={item.value} />
      case 'number':
        return <AnyPropertyRowValueForNumber value={item.value} />
      case 'boolean':
        return <AnyPropertyRowValueForBoolean value={item.value} />
      case 'empty':
        return <AnyPropertyRowValueForEmpty value={item.value} />
      case 'enum':
        return <AnyPropertyRowValueForEnum value={item.value} />
      case 'json':
        return <AnyPropertyRowValueForJson value={item.value} />
      case 'custom':
        return <AnyPropertyRowValueForCustom value={item.value} />
      case 'email':
        return <AnyPropertyRowValueForEmail value={item.value} />
      case 'link':
        return <AnyPropertyRowValueForLink value={item.value} />
    }
  }, [item.type, item.value])
}
