import { TableCell, Text } from '@fluentui/react-components'

interface IProps {
  readonly value: boolean
}

export function AnyPropertyRowValueForBoolean({ value }: IProps) {
  return (
    <TableCell title={value.toString()}>
      <Text>{value}</Text>
    </TableCell>
  )
}
