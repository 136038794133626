export enum AnyDataTab {
  Table = 'Table',
  Property = 'Property',
  Json = 'JSON',
}

export interface IAnyDataOptions {
  readonly tabs?: AnyDataTab[]
  readonly table?: IAnyTableOptions
  readonly json?: IAnyJsonOptions
  readonly property?: IAnyPropertyOptions
}

export interface IAnyTableOptions {
  readonly tabName?: string
  readonly tabDescription?: string
}

export interface IAnyJsonOptions {
  readonly tabName?: string
  readonly tabDescription?: string
  readonly wrap?: boolean
}

export interface IAnyPropertyOptions {
  readonly tabName?: string
  readonly tabDescription?: string
}
