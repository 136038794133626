import { useCallback, useEffect, useMemo } from 'react'
import { createNavigator } from '../../navigator/createNavigator'
import { RootCauseRouteArgs } from './RootCauseRouteMapper'
import { ITeamViewRouteArgs } from './types'
import {
  getProductNameById,
  IProductData,
  TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST,
  TeamViewSubMenuIds,
} from '@copilot-dash/domain'
import { GlobalProductSelector } from '../../../providers/product'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { TeamScreen } from '../../../screens/team/TeamScreen'
import { Spinner } from '@fluentui/react-components'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'
import { TeamViewSearchTicketArgsNames } from '@copilot-dash/store'
import { ErrorView } from '../../../components/Error'

interface IRootCausePageProps {
  rootCauseNavigator: ReturnType<typeof createNavigator<RootCauseRouteArgs>>
  teamViewNavigator: ReturnType<typeof createNavigator<ITeamViewRouteArgs>>
}

export function RootCausePage(props: IRootCausePageProps) {
  const { rootCauseNavigator, teamViewNavigator } = props
  const rootCauseNavArgs = rootCauseNavigator.useArgs()

  const handleSelectProduct = useCallback(
    (product: IProductData) => {
      teamViewNavigator.navigate({
        product: product.name,
      })
    },
    [teamViewNavigator],
  )

  const rootCauseInfoSnapshot = app.store.use.getRootCauseInfoById(
    rootCauseNavArgs.rootCauseId,
    rootCauseNavArgs.vsoAccount,
  )

  const teamListSnapshot = app.store.use((state) => state.team.teams?.[rootCauseInfoSnapshot.data?.productId ?? -1])

  useEffect(() => {
    if (rootCauseInfoSnapshot.status === 'done') {
      const rootCauseInfo = rootCauseInfoSnapshot.data
      if (rootCauseInfo?.productId) {
        app.store.actions.getTeams(rootCauseInfo.productId)
      }
    }
  }, [rootCauseInfoSnapshot.data, rootCauseInfoSnapshot.status])

  const args = useMemo(() => {
    const rootCauseInfo = rootCauseInfoSnapshot.data
    if (!rootCauseInfo) {
      return null
    }
    const productName = getProductNameById(rootCauseInfo.productId)
    if (!productName) {
      return null
    }
    const teamName = teamListSnapshot?.data?.find((team) => team.id === rootCauseInfo.teamId)?.name
    if (!teamName) {
      return null
    }
    return {
      product: productName,
      subMenu: TeamViewSubMenuIds.RootCauses,
      [TeamViewSearchTicketArgsNames.issueId]: rootCauseInfo.issueId,
      [TeamViewSearchTicketArgsNames.vsoAccount]: rootCauseInfo.vsoAccount,
      [TeamViewSearchTicketArgsNames.teamId]: rootCauseInfo.teamId,
      [TeamViewSearchTicketArgsNames.teamName]: teamName,
    }
  }, [rootCauseInfoSnapshot.data, teamListSnapshot?.data])

  if (rootCauseInfoSnapshot.status === 'waiting' || teamListSnapshot?.status === 'waiting') {
    return <Spinner>Loading...</Spinner>
  }
  if (rootCauseInfoSnapshot.status === 'error' || teamListSnapshot?.status === 'error') {
    return <ErrorView error={rootCauseInfoSnapshot.error ?? teamListSnapshot?.error} />
  }

  if (!args) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector
      value={args.product}
      onChange={handleSelectProduct}
      isItemDisabled={(product) => !TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product.name)}
    >
      <PageTitle title="Team">
        <TeamScreen key={args.product} args={args} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
