import { makeStyles, TableRow, tokens } from '@fluentui/react-components'
import { AnyPropertyItem } from '../AnyPropertyType'
import { AnyPropertyRowName } from './AnyPropertyRowName'
import { AnyPropertyRowValue } from './AnyPropertyRowValue'

interface IProps {
  readonly item: AnyPropertyItem
}

export function AnyPropertyRow({ item }: IProps) {
  const styles = useStyles()

  return (
    <TableRow className={styles.root}>
      <AnyPropertyRowName name={item.name} />
      <AnyPropertyRowValue item={item} />
    </TableRow>
  )
}

const useStyles = makeStyles({
  root: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground2,
    },

    ':active': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
})
