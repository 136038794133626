import { IRouteMapper } from '../../types/IRouteMapper'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_ROOT_CAUSE_ID = 'rootCauseId'

export type RootCauseRouteArgs = {
  readonly rootCauseId: string
  readonly vsoAccount: string
}

export const RootCauseRouteMapper: IRouteMapper<RootCauseRouteArgs> = {
  mapToParams(args: RootCauseRouteArgs) {
    return {
      pathParams: {
        [PATH_PARAM_ROOT_CAUSE_ID]: args.rootCauseId,
      },
      queryParams: {
        vsoAccount: args.vsoAccount,
      },
    }
  },
  mapToArgs(data) {
    const path = new RouteDataReader(data.pathParams)
    const queryParams = new RouteDataReader(data.queryParams)
    const rootCauseId = path.getString(PATH_PARAM_ROOT_CAUSE_ID)
    const vsoAccount = queryParams.getString('vsoAccount')
    if (rootCauseId === undefined || vsoAccount === undefined) {
      throw new Error(
        `Unable to map route params to 'RootCauseRouteArgs'. Missing required path param: '${PATH_PARAM_ROOT_CAUSE_ID}', 'vsoAccount'`,
      )
    }

    return {
      rootCauseId,
      vsoAccount,
    }
  },
}
