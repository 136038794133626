import { AnyDataTab } from '../../AnyDataTypes'

export function getSupportedTabs(data: unknown): AnyDataTab[] {
  return Object.values(AnyDataTab).filter((tab) => {
    switch (tab) {
      case AnyDataTab.Table:
        return Array.isArray(data) && data.length > 0
      case AnyDataTab.Json:
        return true
      case AnyDataTab.Property:
        return typeof data === 'object' && !Array.isArray(data)
    }
  })
}
