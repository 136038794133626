import { makeStyles, Tab } from '@fluentui/react-components'
import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../AnyDataTypes'

interface IProps {
  readonly tab: AnyDataTab
}

export function AnyDataBarTab({ tab }: IProps) {
  const styles = useStyles()
  const tableOption = AnyDataStore.use((state) => state.table.options)
  const jsonOption = AnyDataStore.use((state) => state.json.options)
  const propertyOption = AnyDataStore.use((state) => state.table.options)

  let name: string | object = {}
  let title: string | -1 = -1
  switch (tab) {
    case AnyDataTab.Table:
      name = tableOption.tabName || 'Table'
      title = tableOption.tabDescription || 'Display result set as a table'
      break
    case AnyDataTab.Json:
      name = jsonOption.tabName || 'JSON'
      title = jsonOption.tabDescription || 'Display result set as JSON'
      break
    case AnyDataTab.Property:
      name = propertyOption.tabName || 'Property'
      title = propertyOption.tabDescription || 'Display result set as a property list'
      break
  }

  return (
    <Tab className={styles.root} value={tab} title={title}>
      {name}
    </Tab>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
})
