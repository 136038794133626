import { Times } from '@copilot-dash/core'
import { makeStyles, TableCell, Text, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row, Spacer } from '../../Layout'

interface IProps {
  readonly value: string
}

export function AnyPropertyRowValueForDatetime({ value }: IProps) {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()

  const displayText = useMemo(() => {
    return Times.format(value, { timezone }) ?? String(value)
  }, [timezone, value])

  return (
    <TableCell title={value}>
      <Row className={styles.container} vAlign="center">
        <Text>{displayText}</Text>
        <Spacer width={8} />
        <Text className={styles.timezone} title={timezone}>
          {timezone}
        </Text>
      </Row>
    </TableCell>
  )
}

const useStyles = makeStyles({
  container: {
    gap: '4px',
  },
  timezone: {
    color: tokens.colorNeutralForegroundDisabled,
  },
  icon: {
    color: tokens.colorNeutralForegroundDisabled,
  },
})
