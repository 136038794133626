import { Times } from '@copilot-dash/core'
import { startCase } from 'lodash'
import { z } from 'zod'
import { AnyPropertyItem } from '../AnyPropertyType'

const urlSchema = z.string().url()
const emailSchema = z.string().email()

const enumKeyKeyword = ['status', 'ring']
const enumArraySchema = z.array(z.union([z.string(), z.number(), z.boolean()]))

export function generateAnyPropertyItem(key: string, value: unknown): AnyPropertyItem {
  const name = startCase(key)

  // Empty
  if (value === null || value === undefined || value === '') {
    return { name, type: 'empty', value }
  }

  // URL
  if (typeof value === 'string' && value.trim().startsWith('http')) {
    const url = urlSchema.safeParse(value)
    if (url.success) {
      return { name, type: 'link', value: url.data }
    }
  }

  // Email
  const email = emailSchema.safeParse(value)
  if (email.success) {
    return { name, type: 'email', value: email.data }
  }

  // DateTime
  if (typeof value === 'string' && value.length >= 8 && Times.isValid(value)) {
    return { name, type: 'datetime', value }
  }

  // Enum
  if (typeof value === 'string' || typeof value === 'number') {
    if (enumKeyKeyword.some((keyword) => key.toLowerCase().trim() === keyword.toLowerCase().trim())) {
      return { name, type: 'enum', value }
    }
  }

  // String
  if (typeof value === 'string') {
    return { name, type: 'string', value }
  }

  // Number
  if (typeof value === 'number') {
    return { name, type: 'number', value }
  }

  // Boolean
  if (typeof value === 'boolean') {
    return { name, type: 'boolean', value }
  }

  // Enum Array
  if (Array.isArray(value) && value.length === 0) {
    return { name, type: 'empty', value: [] }
  }
  const enumArray = enumArraySchema.safeParse(value)
  if (enumArray.success) {
    return { name, type: 'enum', value: enumArray.data }
  }

  // Empty Object
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return { name, type: 'empty', value: {} }
  }

  return { name, type: 'json', value }
}
