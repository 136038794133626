import { useMemo } from 'react'
import { Route } from 'react-router-dom'
import { createNavigator } from '../../navigator/createNavigator'
import { RootCauseRouteMapper } from './RootCauseRouteMapper'
import { TeamViewRouteMapper } from './TeamRouteMapper'
import { RootCausePage } from './RootCausePage'

export function RootCause() {
  const rootCauseNavigator = useMemo(() => createNavigator(ID, PATH, RootCauseRouteMapper), [])
  const teamViewNavigator = useMemo(() => createNavigator(TeamViewNavID, TeamViewNavPath, TeamViewRouteMapper), [])
  return (
    <Route
      id={ID}
      path={PATH}
      element={<RootCausePage rootCauseNavigator={rootCauseNavigator} teamViewNavigator={teamViewNavigator} />}
    />
  )
}

const ID = 'product.team.rootCause'
const PATH = '/rootCause/:rootCauseId'

const TeamViewNavID = 'product.team'
const TeamViewNavPath = '/:product/team'

RootCause.id = ID
RootCause.path = PATH
RootCause.navigator = createNavigator(ID, PATH, RootCauseRouteMapper)
