import { Avatar, makeStyles, Tooltip } from '@fluentui/react-components'
import { useMemo } from 'react'
import { TicketConversationHistory } from '../../../TicketConversationHistory'

export function UserMessageTileAvatar() {
  const ticketId = TicketConversationHistory.use((state) => state.ticketId)
  const emailSnapshot = app.store.use.getTicketEmail(ticketId)
  const email = emailSnapshot.data

  if (email && email.endsWith('@microsoft.com')) {
    return <AvatarViewForMSIT email={email} />
  } else {
    return <AvatarView />
  }
}

function AvatarViewForMSIT({ email }: { email: string }) {
  const profile = app.store.use.getUserProfile(email)
  const photo = app.store.use.getUserPhoto(email)

  const photoUrl = useMemo(() => {
    if (photo.status === 'done') {
      // blob to url
      return URL.createObjectURL(photo.data)
    }

    return undefined
  }, [photo])

  return <AvatarView email={email} displayName={profile.data?.displayName ?? undefined} photoUrl={photoUrl} />
}

function AvatarView({ email, displayName, photoUrl }: { email?: string; displayName?: string; photoUrl?: string }) {
  const styles = useStyles()

  return (
    <Tooltip content={displayName ?? email ?? ''} relationship={'label'}>
      <Avatar
        image={{
          src: photoUrl,
        }}
        className={styles.root}
        size={24}
      />
    </Tooltip>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    marginTop: '4px',
  },
  img: {
    backgroundColor: 'transparent',
  },
})
