import { IDashAuth } from '@copilot-dash/auth'
import { LoaderFunctionArgs, Route } from 'react-router-dom'
import { NotFoundRoute } from './404/NotFoundRoute'
import { HomeRoute } from './home/HomeRoute'
import { LoginRoute } from './login/LoginRoute'
import { LogoutRoute } from './logout/LogoutRoute'
import { SearchRoute } from './product.search/SearchRoute'
import { TeamRoute } from './product.team/TeamRoute'
import { ProductRouteForRedirecting } from './product/ProductRouteForRedirecting'
import { RootRouteError } from './RootRouteError'
import { RootRoutePage } from './RootRoutePage'
import { SearchRouteForRedirecting } from './search/SearchRouteForRedirecting'
import { SettingsRoute } from './settings/SettingsRoute'
import { TeamRouteForRedirecting } from './team/TeamRouteForRedirecting'
import { TicketDebugRoute } from './ticket.id.debug/TicketDebugRoute'
import { TicketRoute } from './ticket/TicketRoute'
import { McpExperimentRoute } from './product.mcpexperimentfeedback/McpExperimentRoute'
import { WorkItemRoute } from './workItem/WorkItemRoute'
import { RootCause } from './product.team/RootCause'

export function RootRoute(auth: IDashAuth) {
  return (
    <Route id="root" element={<RootRoutePage />} errorElement={<RootRouteError />}>
      {/* 1. Private */}
      <Route loader={privateRouteLoader} errorElement={<RootRouteError />}>
        {/* 1.1 Home */}
        {HomeRoute(auth)}

        {/* 1.2 Product */}
        {ProductRouteForRedirecting()}

        {/* 1.3 Search */}
        {SearchRoute()}
        {SearchRouteForRedirecting()}

        {/* 1.4 Team */}
        {TeamRoute()}
        {TeamRouteForRedirecting()}
        {RootCause()}

        {/* 1.5 Ticket */}
        {TicketRoute()}
        {TicketDebugRoute()}
        {WorkItemRoute()}

        {/* 1.6 Settings */}
        {SettingsRoute()}

        {McpExperimentRoute()}
      </Route>

      {/* Public */}
      {LoginRoute(auth)}
      {LogoutRoute()}

      {/* 404 */}
      {NotFoundRoute()}
    </Route>
  )

  async function privateRouteLoader({ request }: LoaderFunctionArgs): Promise<Response | null> {
    // Case 1: Logged in, allow access
    if (auth.isAuthenticated) {
      return null
    }

    // Case 2: Redirect to login page
    const url = new URL(request.url)
    if (url.pathname === '/') {
      return LoginRoute.navigator.generateRedirectResponse({})
    }

    // Case 3: Redirect to login page (Microsoft)
    await auth.login()
    return null
  }
}
