import { AnyDataTab, IAnyDataOptions } from '../AnyDataTypes'
import { IState } from './IState'
import { getSupportedTabs } from './utils/getSupportedTabs'

interface IParams {
  readonly data: unknown
  readonly options: IAnyDataOptions
}

export function createState({ data, options }: IParams): IState {
  const tabsFromData = getSupportedTabs(data)
  const tabsFromOptions = options?.tabs ?? []
  const tabs = tabsFromOptions.length > 0 ? tabsFromOptions : tabsFromData

  return {
    data,
    tabs,
    selectedTab: tabs[0] ?? AnyDataTab.Json,
    table: {
      options: options?.table ?? {},
    },
    json: {
      options: options?.json ?? {},
      wrap: options?.json?.wrap ?? false,
    },
    property: {
      options: options?.property ?? {},
    },
  }
}
