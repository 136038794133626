import { ITimezoneData, Times } from '@copilot-dash/core'
import { ReactElement, useMemo } from 'react'
import { TimezoneViewPopover } from './TimezoneViewPopover'

interface IProps {
  readonly children?: ((data: ITimezoneData) => ReactElement) | ReactElement
}

export function TimezoneView({ children }: IProps) {
  const timezone = app.settings.timezone.use()
  const data = useMemo(() => Times.getTimezoneData(timezone), [timezone])

  let finalChildren: ReactElement
  if (typeof children === 'function') {
    finalChildren = children(data)
  } else if (children) {
    finalChildren = children
  } else {
    finalChildren = <span>{data.valueLabel}</span>
  }

  return <TimezoneViewPopover>{finalChildren}</TimezoneViewPopover>
}
