import { makeStyles, TableCell, Text } from '@fluentui/react-components'

interface IProps {
  readonly value: number
}

export function AnyPropertyRowValueForNumber({ value }: IProps) {
  const styles = useStyles()

  return (
    <TableCell className={styles.root} title={value.toString()}>
      <Text weight="semibold">{value.toString()}</Text>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    // color: tokens.colorPaletteBlueForeground2,
  },
})
