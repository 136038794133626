import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../AnyDataTypes'
import { AnyJson } from './Json/AnyJson'
import { AnyProperty } from './property/AnyProperty'
import { AnyTable } from './table/AnyTable'

export function AnyDataBody() {
  const selectedTab = AnyDataStore.use((state) => state.selectedTab)

  switch (selectedTab) {
    case AnyDataTab.Table:
      return <AnyTable />
    case AnyDataTab.Json:
      return <AnyJson />
    case AnyDataTab.Property:
      return <AnyProperty />
  }
}
