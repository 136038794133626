import { ErrorViewBoundary } from '../Error'
import { AnyDataStore } from './AnyDataStore'
import { IAnyDataOptions } from './AnyDataTypes'
import { AnyDataLayout } from './layout/AnyDataLayout'

interface IProps {
  readonly data: unknown
  readonly options?: IAnyDataOptions
}

export function AnyData({ data, options = {} }: IProps) {
  return (
    <ErrorViewBoundary label="AnyData">
      <AnyDataStore data={data} options={options}>
        <AnyDataLayout />
      </AnyDataStore>
    </ErrorViewBoundary>
  )
}
