import { makeStyles, tokens } from '@fluentui/react-components'
import { AnyData } from '../../../../../components/AnyData/AnyData'
import { Column } from '../../../../../components/Layout'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketAsyncView } from '../../common/TicketAsyncView'

export function TicketContextView() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketContextSnapshot = app.store.use.getTicketContext(ticketId)

  return (
    <Column className={styles.root} vAlign="stretch" hAlign="stretch">
      <TicketAsyncView snapshot={ticketContextSnapshot}>
        {(data) => {
          return <AnyData data={data} />
        }}
      </TicketAsyncView>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: '8px 16px',
    border: `1px solid ${tokens.colorNeutralStroke2}`,
    overflow: 'hidden',
  },
})
