import { PromiseSnapshot } from '@copilot-dash/core'
import { get3sLogs } from './actions/actions-3s/get3sLogs'
import { get3sOfflineGeneralDiagnosticData } from './actions/actions-3s/get3sOfflineGeneralDiagnosticData'
import { get3sOnlineGeneralDiagnosticData } from './actions/actions-3s/get3sOnlineGeneralDiagnosticData'
import { getTicketChatIndex } from './actions/actions-conversation/getTicketChatIndex'
import { getTicketChatTurn } from './actions/actions-conversation/getTicketChatTurn'
import { get3sGwsLog } from './actions/actions-kusto/get3sGwsLog'
import { get3SLatencyLogs } from './actions/actions-kusto/get3SLatencyLogs'
import { getExtensibilityLog } from './actions/actions-kusto/getExtensibilityLog'
import { getStateDurationLog } from './actions/actions-kusto/getStateDurationLog'
import { getTicketKustoData } from './actions/actions-kusto/getTicketKustoData'
import { getRaw3sOfflineDataGroup1 } from './actions/actions-raw-3s/getRaw3sOfflineDataGroup1'
import { getRaw3sOfflineDataGroup2 } from './actions/actions-raw-3s/getRaw3sOfflineDataGroup2'
import { getRaw3sOfflineDataGroup3 } from './actions/actions-raw-3s/getRaw3sOfflineDataGroup3'
import { getRaw3sOfflineDataGroup4 } from './actions/actions-raw-3s/getRaw3sOfflineDataGroup4'
import { getRaw3sOnlineDataGroup1 } from './actions/actions-raw-3s/getRaw3sOnlineDataGroup1'
import { getRaw3sOnlineDataGroup2 } from './actions/actions-raw-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from './actions/actions-raw-3s/getRaw3sOnlineDataGroup3'
import { getRaw3sOnlineDataGroup4 } from './actions/actions-raw-3s/getRaw3sOnlineDataGroup4'
import { getRawConversationGroup1 } from './actions/actions-raw-conversation/getRawConversationGroup1'
import { getRawConversationGroup2 } from './actions/actions-raw-conversation/getRawConversationGroup2'
import { getRawConversationGroup3 } from './actions/actions-raw-conversation/getRawConversationGroup3'
import { getRawConversationGroup4 } from './actions/actions-raw-conversation/getRawConversationGroup4'
import { getSymptomReport } from './actions/actions-symptom-report/getSymptomReport'
import { getTicketHealthData } from './actions/actions-symptom-report/getTicketHealthData'
import { getCallFlow } from './actions/actions-telemetry/getCallFlow'
import { getTelemetryMetrics } from './actions/actions-telemetry/getTelemetryMetrics'
import { getTicket } from './actions/actions-ticket/getTicket'
import { getTicketContext } from './actions/actions-ticket/getTicketContext'
import { getTicketEmail } from './actions/actions-ticket/getTicketEmail'
import { getTicketResponse } from './actions/actions-ticket/getTicketResponse'
import { getTicketSession } from './actions/actions-ticket/getTicketSession'
import { getTicketUtterance } from './actions/actions-ticket/getTicketUtterance'
import { getTicketVerbatim } from './actions/actions-ticket/getTicketVerbatim'
import { batchUpdateTicketStatusInfo } from './actions/batchUpdateTicketStatusInfo'
import { createRootCause } from './actions/createRootCause'
import { createTicketComment } from './actions/createTicketComment'
import { deleteQuery } from './actions/deleteQuery'
import { deleteTicketComment } from './actions/deleteTicketComment'
import { getAllCustomTags } from './actions/getAllCustomTags'
import { getAssignedTeamMembers } from './actions/getAssignedTeamMembers'
import { getAttachmentUrl } from './actions/getAttachmentUrl'
import { getBatchTicketsCustomTags } from './actions/getBatchTicketsCustomTags'
import { getFilterTags } from './actions/getFilterTags'
import { getMyQueries } from './actions/getMyQueries'
import { getProducts } from './actions/getProducts'
import { getQuery } from './actions/getQuery'
import { getRawConversation } from './actions/getRawConversation'
import { getRawOcvTicket } from './actions/getRawOcvTicket'
import {
  appendRootCauseByTeam,
  getOrFetchRootCauseListByTeam,
  getRootCauseListByTeam,
  searchRootCauseByTicketCharacter,
  updateRootCauseByTeam,
} from './actions/getRootCauseListByTeam'
import { getTeamAreasAndRootCauses } from './actions/getTeamAreasAndRootCauses'
import { getTeamDRIListByTeamId, setTeamDRIList } from './actions/getTeamDRIListByTeamId'
import {
  getOrFetchTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringBatchInfoList,
} from './actions/getTeamIssueClusteringBatchInfoList'
import {
  getOrFetchTeamIssueClusteringInfoList,
  getTeamIssueClusteringInfoList,
} from './actions/getTeamIssueClusteringInfoList'
import { getTeamQueries } from './actions/getTeamQueries'
import { getTeamRootCauses } from './actions/getTeamRootCauses'
import { getTeams } from './actions/getTeams'
import { getTicketActivityHistory } from './actions/getTicketActivityHistory'
import { getTicketChatTurnCitations } from './actions/getTicketChatTurnCitations'
import { getTicketCommentCount } from './actions/getTicketCommentCount'
import { getTicketComments } from './actions/getTicketComments'
import { getTicketCustomTags } from './actions/getTicketCustomTags'
import { getTicketIdByWorkItemId } from './actions/getTicketIdByWorkItemId'
import { getTicketRootCause } from './actions/getTicketRootCause'
import { getTopIssueExtendInfo } from './actions/getTopIssueExtendInfo'
import { getUserPhoto } from './actions/getUserPhoto'
import { getUserProfile } from './actions/getUserProfile'
import { getMcpExperimentFeedback } from './actions/mcpExperimentFeedback/getMcpExperimentFeedback'
import { removeTicketFromTopIssue } from './actions/removeTicketFromTopIssue'
import { searchM365Tenants } from './actions/searchM365Tenants'
import { searchTickets } from './actions/searchTickets'
import { updateAllCustomTags } from './actions/updateAllCustomTags'
import { updateQuery } from './actions/updateQuery'
import { updateRootCause } from './actions/updateRootCause'
import { updateTicket } from './actions/updateTicket'
import { updateTicketComment } from './actions/updateTicketComment'
import { updateTicketCustomTag } from './actions/updateTicketCustomTag'
import { updateTicketRootCause } from './actions/updateTicketRootCause'
import { updateTicketStatusInfo } from './actions/updateTicketStatusInfo'
import { uploadAttachmentToAzureBlob } from './actions/uploadFileToAzureBlob'
import { getOrFetchRootCauseInfoByRootCauseId } from './actions/getRootCauseInfoByRootCauseId'
import { uploadImage } from './actions/uploadImage'
import { IDashStoreContext } from './IDashStoreContext'

export const DefaultDashStoreActions = {
  // Basic
  getFilterTags: getFilterTags,
  searchM365Tenants: searchM365Tenants,
  getProducts: getProducts,

  // Search
  searchTickets: searchTickets,

  // User
  getUserProfile: getUserProfile,
  getUserPhoto: getUserPhoto,

  // Ticket
  getTicket: getTicket,
  getTicketContext: getTicketContext,
  updateTicket: updateTicket,
  getTicketOcv: getRawOcvTicket,
  getTicketSession: getTicketSession,
  getTicketConversation: getTicketChatTurn,
  getTicketUtterance: getTicketUtterance,
  getTicketResponse: getTicketResponse,
  getTicketVerbatim: getTicketVerbatim,
  getTicketEmail: getTicketEmail,
  getTicketConversationManifest: getTicketChatIndex,
  getTicketIdByWorkItemId: getTicketIdByWorkItemId,

  //Custom Tags
  getAllCustomTags: getAllCustomTags,
  getTicketCustomTags: getTicketCustomTags,
  getBatchTicketsCustomTags: getBatchTicketsCustomTags,
  updateAllCustomTags: updateAllCustomTags,
  updateTicketCustomTag: updateTicketCustomTag,

  // Kusto Logs
  getTicketKustoData: getTicketKustoData,
  get3SLatencyLogs: get3SLatencyLogs,
  get3sGwsLog: get3sGwsLog,
  getExtensibilityLog: getExtensibilityLog,
  getStateDurationLog: getStateDurationLog,

  // Comments
  getTicketCommentCount: getTicketCommentCount,
  getTicketComments: getTicketComments,
  updateTicketComment: updateTicketComment,
  createTicketComment: createTicketComment,
  deleteTicketComment: deleteTicketComment,

  // Activity
  getTicketActivityHistory: getTicketActivityHistory,

  // Team
  getTeamAreasAndRootCauses: getTeamAreasAndRootCauses,
  getTeamIssueClusteringBatchInfoList: getTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringInfoList: getTeamIssueClusteringInfoList,
  getTeamRootCauses: getTeamRootCauses,
  getTeams: getTeams,
  getRootCauseListByTeam: getRootCauseListByTeam,
  getOrFetchRootCauseListByTeam: getOrFetchRootCauseListByTeam,
  searchRootCauseByTicketCharacter: searchRootCauseByTicketCharacter,
  appendRootCauseByTeam: appendRootCauseByTeam,
  updateRootCauseByTeam: updateRootCauseByTeam,
  getAssignedTeamMembers: getAssignedTeamMembers,
  getTeamDRIListByTeamId: getTeamDRIListByTeamId,
  setTeamDRIList: setTeamDRIList,

  // Top Issues
  getOrFetchTeamIssueClusteringBatchInfoList: getOrFetchTeamIssueClusteringBatchInfoList,
  getOrFetchTeamIssueClusteringInfoList: getOrFetchTeamIssueClusteringInfoList,
  getTopIssueExtendInfo: getTopIssueExtendInfo,
  removeTicketFromTopIssue: removeTicketFromTopIssue,

  // Root Cause
  updateTicketRootCause: updateTicketRootCause,
  getTicketRootCause: getTicketRootCause,
  updateRootCause: updateRootCause,
  createRootCause: createRootCause,
  getRootCauseInfoById: getOrFetchRootCauseInfoByRootCauseId,

  batchUpdateTicketStatusInfo: batchUpdateTicketStatusInfo,
  updateTicketStatusInfo: updateTicketStatusInfo,

  // Experiment Feedback
  getMcpExperimentFeedback: getMcpExperimentFeedback,

  // Conversation
  getRawConversation: getRawConversation,
  getRawConversationGroup1: getRawConversationGroup1,
  getRawConversationGroup2: getRawConversationGroup2,
  getRawConversationGroup3: getRawConversationGroup3,
  getRawConversationGroup4: getRawConversationGroup4,

  // 3S
  get3sLogs: get3sLogs,
  get3sOnlineGeneralDiagnosticData: get3sOnlineGeneralDiagnosticData,
  get3sOfflineGeneralDiagnosticData: get3sOfflineGeneralDiagnosticData,

  // 3S Offline
  getRaw3sOfflineDataGroup1: getRaw3sOfflineDataGroup1,
  getRaw3sOfflineDataGroup2: getRaw3sOfflineDataGroup2,
  getRaw3sOfflineDataGroup3: getRaw3sOfflineDataGroup3,
  getRaw3sOfflineDataGroup4: getRaw3sOfflineDataGroup4,

  // 3S Replay
  getRaw3sOnlineDataGroup1: getRaw3sOnlineDataGroup1,
  getRaw3sOnlineDataGroup2: getRaw3sOnlineDataGroup2,
  getRaw3sOnlineDataGroup3: getRaw3sOnlineDataGroup3,
  getRaw3sOnlineDataGroup4: getRaw3sOnlineDataGroup4,

  // Citations
  getTicketChatTurnCitations: getTicketChatTurnCitations,

  // Telemetry & CallFlow
  getCallFlow: getCallFlow,
  getTelemetryMetrics: getTelemetryMetrics,

  // Queries
  updateQuery: updateQuery,
  getTeamQueries: getTeamQueries,
  getMyQueries: getMyQueries,
  getQuery: getQuery,
  deleteQuery: deleteQuery,

  // SymptomReport
  getTicketHealthData: getTicketHealthData,
  getSymptomReport: getSymptomReport,

  //Attachment
  uploadImage: uploadImage,
  uploadAttachmentToAzureBlob: uploadAttachmentToAzureBlob,
  getAttachmentUrl: getAttachmentUrl,
} satisfies {
  readonly [name: string]: (context: IDashStoreContext, ...args: never[]) => PromiseSnapshot<unknown> | Promise<unknown>
}
