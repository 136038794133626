import { Avatar, makeStyles, Persona, Slot, TableCell } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row } from '../../Layout'

interface IProps {
  readonly value: string
}

export function AnyPropertyRowValueForEmail({ value }: IProps) {
  const styles = useStyles()
  const isMSIT = useMemo(() => {
    return value.endsWith('@microsoft.com')
  }, [value])

  return (
    <TableCell className={styles.root} title={value}>
      {isMSIT ? <ContentForMSIT value={value} /> : <Content email={value} />}
    </TableCell>
  )
}

function ContentForMSIT({ value }: IProps) {
  const profile = app.store.use.getUserProfile(value)

  const photo = app.store.use.getUserPhoto(value)
  const photoUrl: string | undefined = useMemo(() => {
    switch (photo.status) {
      case 'done':
        return URL.createObjectURL(photo.data)
      default:
        return undefined
    }
  }, [photo.data, photo.status])

  return <Content email={value} displayName={profile.data?.displayName ?? undefined} photoUrl={photoUrl} />
}

function Content({ email, displayName, photoUrl }: { email: string; displayName?: string; photoUrl?: string }) {
  let avatar: Slot<typeof Avatar>
  if (photoUrl) {
    avatar = {
      image: {
        src: photoUrl,
      },
    }
  } else {
    avatar = {
      color: 'colorful',
      'aria-hidden': true,
    }
  }

  const name = displayName ?? email
  const secondaryText = displayName ? email : undefined

  return (
    <Row vAlign="center">
      <Persona avatar={avatar} name={name} secondaryText={secondaryText} size="small" textAlignment="center" />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px',
  },
})
