import { makeStyles } from '@fluentui/react-components'

export const FILTER_PANEL_WIDTH = 360

export const useStyles = makeStyles({
  drawer: {
    width: `${FILTER_PANEL_WIDTH}px`,
    height: '100%',
  },
  horizontalCardImage: {
    width: '64px',
    height: '64px',
  },
  body: {
    padding: '0 ',
  },
  footer: {
    margin: '0',
    padding: '0',
  },
  footerRow: {
    padding: '0 24px 8px 24px',
  },
  header: {
    margin: '0',
    padding: '0',
  },
  headerTitle: {
    margin: '8px 0 0 0',
    padding: '0px 24px',
  },
  divider: {
    marginBottom: '8px',
  },
  resetButton: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '60px',
  },
  scrollBar: {
    padding: '0 24px',
    height: '100%',
  },
})
