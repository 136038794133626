import { z } from 'zod'

export interface ApiGetRootCauseInfoByIdRequest {
  RootCauseId: string
  VsoAccount: string
}

export interface ApiGetRootCauseInfoByIdResponse {
  info: {
    productId: number
    teamId: number
    rootCauseTitle: string
    issueId: string
    vsoAccount: string
    eTA?: string
    owner?: string
    rootCauseStatus?: string
    rootCausePriority?: number
    enable: boolean
    visible: boolean
  }
  errorMessage: string
}

export const ApiGetRootCauseInfoByIdResponseSchema = z.object({
  info: z.object({
    productId: z.number(),
    teamId: z.number(),
    rootCauseTitle: z.string(),
    issueId: z.string(),
    vsoAccount: z.string(),
    eTA: z.string().optional(),
    owner: z.string().optional(),
    rootCauseStatus: z.string().optional(),
    rootCausePriority: z.number().optional(),
    enable: z.boolean(),
    visible: z.boolean(),
  }),
  errorMessage: z.string(),
})
