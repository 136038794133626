import { useMemo } from 'react'
import { AnyPropertyView } from '../../../../AnyProperty/AnyPropertyView'
import { generateAnyPropertyItems } from '../../../../AnyProperty/utils/generateAnyPropertyItems'
import { Scrollbar } from '../../../../Scrollbar/Scrollbar'
import { AnyDataStore } from '../../../AnyDataStore'

export function AnyProperty() {
  const data = AnyDataStore.use((state) => state.data)
  const items = useMemo(() => {
    return generateAnyPropertyItems(data)
  }, [data])

  return (
    <Scrollbar>
      <AnyPropertyView items={items} />
    </Scrollbar>
  )
}
