import { TableCell } from '@fluentui/react-components'
import { ReactNode } from 'react'

interface IProps {
  readonly value: () => ReactNode
}

export function AnyPropertyRowValueForCustom({ value }: IProps) {
  return <TableCell>{value()}</TableCell>
}
