import { z } from 'zod'

export interface ApiAreaListAndRootCauseListResponse {
  readonly areaPathAndRootCauseList: Array<{
    readonly areaPath?: string // for v1 api
    readonly teamArea?: string // for v2 api
    readonly rootCauseList: Array<{
      readonly issueId: string
      readonly title: string
      readonly vsoAccount: string
      readonly visible: boolean
    }>
  }>
  readonly errorMessage: string
}

export const apiAreaListAndRootCauseListResponseSchema = z.object({
  areaPathAndRootCauseList: z.array(
    z.object({
      areaPath: z.string().optional(),
      teamArea: z.string().optional(),
      rootCauseList: z.array(
        z.object({
          issueId: z.string(),
          title: z.string(),
          vsoAccount: z.string(),
          visible: z.boolean(),
        }),
      ),
    }),
  ),
  errorMessage: z.string(),
})
