import { IRootCauseList } from '@copilot-dash/domain'
import { Combobox, ComboboxProps, Divider, Option, Text } from '@fluentui/react-components'
import { AddRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../Layout'

import { useStyles } from '../RootCauses.styles'
import { motion } from 'framer-motion'
import { useDebounce } from '../../../../hooks/useDebounce'

interface IRootCauseSelectorProps {
  value: string | undefined | null
  onChange: (value: string) => void
  disabledValues?: string[]
  onRemove?: () => void
  onCreateNewRootCauseClicked?: () => void
  defaultValueText?: string
  disabled?: boolean
  teamName?: string
  allowCreateNewRootCause?: boolean
  rootCauseGrouped?: Record<string, IRootCauseList>
}
export const RootCauseSelector: FC<IRootCauseSelectorProps> = memo(
  ({
    value,
    onChange,
    disabledValues,
    onRemove,
    onCreateNewRootCauseClicked,
    defaultValueText,
    disabled,
    teamName,
    allowCreateNewRootCause = false,
    rootCauseGrouped = {},
  }) => {
    const styles = useStyles()
    const [focusDisplayValue, setFocusDisplayValue] = useState('')
    const debouncedFocusDisplayValue = useDebounce(focusDisplayValue, 300)
    const [isFocused, setIsFocused] = useState(false)

    const [expandedTeams, setExpandedTeams] = useState<string[]>(() => {
      let defaultExpandedTeam: string
      if (value) {
        defaultExpandedTeam =
          Object.entries(rootCauseGrouped).find(([_, rootCauseList]) => {
            return rootCauseList.some((rootCause) => value === rootCause.issueId)
          })?.[0] ?? ''
      } else {
        defaultExpandedTeam = teamName ?? Object.keys(rootCauseGrouped)[0] ?? ''
      }
      return defaultExpandedTeam ? [defaultExpandedTeam] : []
    })

    const sortedRootCauseGroups: Array<{
      teamName: string
      rootCauseList: IRootCauseList
      expanded: boolean
    }> = useMemo(() => {
      const sortedPairs = Object.entries(rootCauseGrouped).sort((a, b) => {
        if (a[0] === teamName) return -1
        if (b[0] === teamName) return 1
        return a[0].localeCompare(b[0])
      })
      return sortedPairs
        .map(([_teamName, rootCauseList]) => ({
          teamName: _teamName,
          rootCauseList:
            debouncedFocusDisplayValue.trim().length > 0
              ? rootCauseList.filter((rootCause) =>
                  rootCause.title.toLocaleLowerCase().includes(debouncedFocusDisplayValue.toLocaleLowerCase().trim()),
                )
              : rootCauseList,
          expanded: expandedTeams.includes(_teamName),
        }))
        .filter((item) => {
          return item.rootCauseList.length > 0
        })
    }, [rootCauseGrouped, expandedTeams, teamName, debouncedFocusDisplayValue])

    useEffect(() => {
      if (debouncedFocusDisplayValue.trim().length > 0) {
        setExpandedTeams(Object.keys(rootCauseGrouped))
      }
    }, [debouncedFocusDisplayValue, rootCauseGrouped, teamName])

    useEffect(() => {
      if (!isFocused) {
        let defaultExpandedTeam: string
        if (value) {
          defaultExpandedTeam =
            Object.entries(rootCauseGrouped).find(([_, rootCauseList]) => {
              return rootCauseList.some((rootCause) => value === rootCause.issueId)
            })?.[0] ?? ''
        } else {
          defaultExpandedTeam = teamName ?? Object.keys(rootCauseGrouped)[0] ?? ''
        }
        setExpandedTeams(defaultExpandedTeam ? [defaultExpandedTeam] : [])
      }
    }, [isFocused, rootCauseGrouped, teamName, value])

    const onRootCauseChange = useCallback(
      (issueId: string) => {
        onChange(issueId)
      },
      [onChange],
    )

    const onFocus = useCallback(() => {
      setFocusDisplayValue('')
      setIsFocused(true)
    }, [])

    const handleDeleteRootCause = useCallback(() => {
      setIsFocused(false)
      onRemove?.()
    }, [setIsFocused, onRemove])

    const onInputChange: ComboboxProps['onChange'] = (event) => {
      const value = event.target.value
      setFocusDisplayValue(value)
    }

    const filterOptions = useCallback(
      (optionsList: IRootCauseList) => {
        const filteredOptions = optionsList
          .filter((item) => {
            return item.title?.toLocaleLowerCase().includes(debouncedFocusDisplayValue.toLocaleLowerCase().trim())
          })
          .sort((a, b) => {
            const x1 = a.title!.toLocaleUpperCase().trim()
            const x2 = b.title!.toLocaleUpperCase().trim()
            if (x1 < x2) {
              return -1
            } else if (x1 > x2) {
              return 1
            } else {
              return 0
            }
          })

        return filteredOptions.length > 0 ? (
          filteredOptions.map((item) => {
            if (!item.title || !item.visible) {
              return
            }
            return (
              <Option
                value={item.issueId}
                key={item.issueId}
                disabled={disabledValues?.includes(item.issueId)}
                text={item.title}
                className={styles.optionText}
              >
                {item.title}
              </Option>
            )
          })
        ) : (
          <Text>No option match your search.</Text>
        )
      },
      [debouncedFocusDisplayValue, disabledValues, styles.optionText],
    )

    const finalOptions = useMemo(() => {
      if (debouncedFocusDisplayValue.trim().length > 0 && sortedRootCauseGroups.length === 0) {
        return <Text>No option match your search.</Text>
      }
      if (debouncedFocusDisplayValue.trim().length === 0 && sortedRootCauseGroups.length === 0) {
        return <Text>No root cause available.</Text>
      }
      return sortedRootCauseGroups.map((group) => {
        return (
          <Column key={group.teamName}>
            <Row
              vAlign="center"
              className={styles.groupHeader}
              onClick={() => {
                if (expandedTeams.includes(group.teamName)) {
                  setExpandedTeams((pre) => pre.filter((name) => name !== group.teamName))
                } else {
                  setExpandedTeams((pre) => [...pre, group.teamName])
                }
              }}
            >
              <Text weight="semibold">{group.teamName} Team</Text>
              <Spacer />
              <motion.div animate={{ rotate: group.expanded ? 0 : 180 }}>
                <ChevronUpRegular fontSize={20} />
              </motion.div>
            </Row>
            {group.expanded ? filterOptions(group.rootCauseList) : null}
            <Divider />
          </Column>
        )
      })
    }, [debouncedFocusDisplayValue, sortedRootCauseGroups, styles.groupHeader, filterOptions, expandedTeams])

    return (
      <Combobox
        disabled={disabled}
        placeholder="Add root cause"
        className={styles.fullWith}
        listbox={{ className: styles.listBox }}
        appearance="underline"
        value={
          (isFocused
            ? focusDisplayValue
            : Object.entries(rootCauseGrouped)
                .map(([_, rootCauseList]) => rootCauseList)
                .flat(1)
                .find((item) => item.issueId === value)?.title) ?? defaultValueText
        }
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={() => {
          setIsFocused(false)
        }}
        selectedOptions={value ? [value] : []}
        onOptionSelect={(_, data) => {
          if (data.selectedOptions[0]) {
            onRootCauseChange(data.selectedOptions[0])
            if (data.optionText) {
              setFocusDisplayValue(data.optionText)
            }
          }
        }}
        clearable={true}
        clearIcon={{
          onClick: () => {
            handleDeleteRootCause()
          },
        }}
      >
        <Column style={{ maxHeight: '50vh' }}>
          {allowCreateNewRootCause ? (
            <>
              <Row vAlign="center" className={styles.createAction} onClick={onCreateNewRootCauseClicked}>
                <Spacer width={4} />
                <AddRegular />
                <Spacer width={4} />
                <Text weight="semibold">Create new root cause</Text>
              </Row>
              <Divider />
            </>
          ) : null}
          {finalOptions}
        </Column>
      </Combobox>
    )
  },
)

RootCauseSelector.displayName = 'RootCauseSelector'
