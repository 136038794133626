import { makeStyles, TableCell, Text } from '@fluentui/react-components'

interface IProps {
  readonly name: string
}

export function AnyPropertyRowName({ name }: IProps) {
  const styles = useStyles()

  return (
    <TableCell className={styles.root} title={name}>
      <Text weight="semibold" block wrap={false} truncate>
        {name}
      </Text>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    width: '200px',
    padding: '0px 16px',
  },
})
