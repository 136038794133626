import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { IAnyDataOptions } from './AnyDataTypes'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { IState } from './store/IState'

interface IProps {
  readonly data: unknown
  readonly children: ReactNode
  readonly options: IAnyDataOptions
}

export function AnyDataStore({ data, options, children }: IProps) {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({ data, options }),
    actions: createActions({ set, get }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.data = data
    })
  }, [store, data])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()

AnyDataStore.use = Context.use
AnyDataStore.useActions = Context.useActions
