import { Button } from '@fluentui/react-components'
import { CheckmarkRegular, CopySelectRegular } from '@fluentui/react-icons'
import { useState } from 'react'

interface IProps {
  readonly content: string | (() => string)
  readonly text?: string
  readonly textWhenCopied?: string
  readonly hideText?: boolean
}

export function CopyButton({ content, text = 'Copy', textWhenCopied = 'Copied', hideText = false }: IProps) {
  const [copied, setCopied] = useState(false)

  const onClick = () => {
    const text = typeof content === 'string' ? content : content()

    navigator.clipboard.writeText(text).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }

  return (
    <Button
      onClick={onClick}
      appearance="subtle"
      title="Copy to clipboard"
      size="small"
      icon={copied ? <CheckmarkRegular fontSize={16} /> : <CopySelectRegular fontSize={16} />}
    >
      {!hideText && (copied ? textWhenCopied : text)}
    </Button>
  )
}
