import { DefaultProductEndpoints } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row, Spacer } from '../../Layout'
import { AnyPropertyRowValueForStringIndicatorEndpoint } from './AnyPropertyRowValueForStringIndicatorEndpoint'

interface IProps {
  readonly value: string
}

export function AnyPropertyRowValueForStringIndicator({ value }: IProps) {
  const styles = useStyles()

  const children = useMemo(() => {
    const endpoint = DefaultProductEndpoints.find((endpoint) => endpoint.name.toLowerCase() === value.toLowerCase())
    if (endpoint) {
      return <AnyPropertyRowValueForStringIndicatorEndpoint value={value} endpoint={endpoint} />
    }

    return null
  }, [value])

  if (!children) {
    return null
  }

  return (
    <Row className={styles.root}>
      <Spacer width={24} />
      {children}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    opacity: 0.38,
  },
})
